import React, { useState, useEffect } from 'react';

import { DropdownSidebarIcon, closeButtonWithCircle, databaseSearchbarIcon } from '../../assets/images';
import './searchbar-styles.css'



const SearchBar = (props) => {
  const { data } = props
  const [searchTerm, setSearchTerm] = useState('');
  const [tags, setTags] = useState([])
  const [filteredData, setFilteredData] = useState([]);

  const handleClose = (input) => {
    const index = tags.indexOf(input)
    const newArray = tags
    if (index != -1) {
        newArray.splice(index, 1)
        setTags(newArray)
        setFilteredData([])
    }
  }

  const handleChange = (e) => {
    const input = e.target.value.toUpperCase();
    setSearchTerm(input);
    if(input == '') {
        setFilteredData([])
        return;
    }
    const dataFound = []
    data.map((name) => {
        if (tags.length > 0) {
            if (name.includes(input)) {
                if (!tags.includes(name)) {
                    dataFound.push(name)
                }
            }
        } else {
            if (name.includes(input)) {
                dataFound.push(name)
            }
        }
    })
    if (dataFound.length > 0) {
        setFilteredData(dataFound)
    } else {
        setFilteredData([])
    }
  };

  const handleClick = (input) => {
    const newData = tags
    newData.push(input)
    setTags(newData)
    setSearchTerm('')
    setFilteredData([])
  }

  return (
    <div className="py-4 w-full">
        <div className='relative w-full'>
            <div className='flex flex-row absolute left-0 searchbar-title-container pr-2'>
                <img className="my-2 ml-3 pt-1" src={databaseSearchbarIcon} width="24px" height="24px"></img>
                <h1 className='searchbar-title'>Source system</h1>
            </div>
            <div className='flex flex-row pr-2 rounded-md border w-11/12  border-gray-300'>
                {
                    tags.length > 0 && (
                    <>
                        <div className='ml-40 flex flex-row pt-2'>
                        {tags.map(tag => (
                        <div className='flex flex-row chosen-tag'>
                            <p className='chosen-tag-name'>{tag}</p>
                            <img className='cursor-pointer mb-1' onClick={() => handleClose(tag)} src={closeButtonWithCircle} width="20px" height="20px"/>
                        </div>   
                        ))}
                        </div>
                    </>)
                }
                <input
                    type="text"
                    placeholder={tags.length == 0 && "Search..."}
                    value={searchTerm}
                    onChange={handleChange}
                    className="pl-40 pr-4 py-2 w-full border-none"
                />
                <div className="left-icon">
                    <DropdownSidebarIcon className="left-icon" color="#667085"/>
                </div>
            </div>
            <div>
                {filteredData.length > 0 && (
                    <div className='absolute w-8/12'>
                        {
                            filteredData.map((name) => (
                                <>
                                    <div className='suggestion-input ml-36 w-full cursor-pointer pl-5 hover:bg-slate-300 chosen-tag-name z-10' onClick={() => handleClick(name)}>
                                        <h1>{name}</h1>
                                    </div>
                                </>
                            ))
                        }
                    </div>
                    
                ) }
            </div>
        </div>
    </div>
  );
};

export default SearchBar;