import React from 'react';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import { ProtectedRoute } from './hooks';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Unauthorized from './pages/Unauthorized';
import AccessDenied from './pages/AccessDenied';
import { EInvoiceListing } from './pages/E-invoice'
import ManagementUser from './pages/ManagementUser';

function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Navigate to="/dashboard" />} />
        <Route path='/login' element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path='/dashboard' element={<Dashboard />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/e-invoice' element={<EInvoiceListing />} />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route path='/management-user' element={<ManagementUser />} />
        </Route>
        <Route path='/unauthorized' element={<Unauthorized />} />
        <Route path='/access-denied' element={<AccessDenied />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRoutes;
