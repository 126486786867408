import React from "react";

import './breadcrumb-styles.css'

const Breadcrumb = (props) => {
    const {data} = props

    return (
        <>
            <nav className="flex py-3 text-gray-700" aria-label="Breadcrumb">
            <ol className="flex flex-row items-center space-x-1 md:space-x-2 rtl:space-x-reverse">

                {
                    data.map(({name, path, isLastIndex}) => (
                        <>
                            <li className="flex items-center">
                                <a href={path} className={`inactive-text items-center ${isLastIndex && 'active-text'}`}
                                style={{
                                    marginLeft: '0px'
                                }}>
                                {name}
                                </a>
                            </li>
                            {!isLastIndex && <span className="active-text">/</span>}
                        </>
                    ))
                }
                  
                  {/* <li>
                     <div className="flex items-center">
                     
                     <a href="#" className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">Templates</a>
                     </div>
                  </li>
                  <li aria-current="page">
                     <div className="flex items-center">
                     <span>/</span>
                     <span className="ms-1 text-sm font-medium text-gray-500 md:ms-2 dark:text-gray-400">Flowbite</span>
                     </div>
                  </li> */}
               </ol>
            </nav>
        </>
    )
}

export default Breadcrumb;