import React from "react";

const EInvoiceSidebarIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : 'white'
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M19 1H5C4.76038 1.00276 4.52971 1.09148 4.35 1.25C5.06314 1.5009 5.69057 1.94876 6.15959 2.54167C6.62861 3.13457 6.92 3.84826 7 4.6C7.01529 4.73289 7.01529 4.86711 7 5V22C6.99953 22.1913 7.05392 22.3787 7.15672 22.5399C7.25952 22.7012 7.40642 22.8297 7.58 22.91C7.75399 22.9901 7.94732 23.0185 8.137 22.9919C8.32669 22.9654 8.50474 22.8848 8.65 22.76L11.5 20.32L14.35 22.76C14.5311 22.9149 14.7616 23.0001 15 23.0001C15.2384 23.0001 15.4689 22.9149 15.65 22.76L18.5 20.32L21.35 22.76C21.5311 22.9149 21.7617 23 22 23C22.1447 22.999 22.2876 22.9683 22.42 22.91C22.5936 22.8297 22.7405 22.7012 22.8433 22.5399C22.9461 22.3787 23.0005 22.1913 23 22V5C23 3.93913 22.5786 2.92172 21.8284 2.17157C21.0783 1.42143 20.0609 1 19 1ZM18 15H12C11.7348 15 11.4804 14.8946 11.2929 14.7071C11.1054 14.5196 11 14.2652 11 14C11 13.7348 11.1054 13.4804 11.2929 13.2929C11.4804 13.1054 11.7348 13 12 13H18C18.2652 13 18.5196 13.1054 18.7071 13.2929C18.8946 13.4804 19 13.7348 19 14C19 14.2652 18.8946 14.5196 18.7071 14.7071C18.5196 14.8946 18.2652 15 18 15ZM18 11H12C11.7348 11 11.4804 10.8946 11.2929 10.7071C11.1054 10.5196 11 10.2652 11 10C11 9.73478 11.1054 9.48043 11.2929 9.29289C11.4804 9.10536 11.7348 9 12 9H18C18.2652 9 18.5196 9.10536 18.7071 9.29289C18.8946 9.48043 19 9.73478 19 10C19 10.2652 18.8946 10.5196 18.7071 10.7071C18.5196 10.8946 18.2652 11 18 11ZM18 7H12C11.7348 7 11.4804 6.89464 11.2929 6.70711C11.1054 6.51957 11 6.26522 11 6C11 5.73478 11.1054 5.48043 11.2929 5.29289C11.4804 5.10536 11.7348 5 12 5H18C18.2652 5 18.5196 5.10536 18.7071 5.29289C18.8946 5.48043 19 5.73478 19 6C19 6.26522 18.8946 6.51957 18.7071 6.70711C18.5196 6.89464 18.2652 7 18 7ZM5 5V13H2C1.73478 13 1.48043 12.8946 1.29289 12.7071C1.10536 12.5196 1 12.2652 1 12V5C1 4.86 1 4.73 1 4.6C1.05304 4.06957 1.31463 3.58193 1.72721 3.24436C2.13979 2.9068 2.66957 2.74696 3.2 2.8C3.73043 2.85304 4.21807 3.11463 4.55563 3.52721C4.8932 3.93979 5.05304 4.46957 5 5Z" fill={filledColor}/>
        </svg>
    )
}

export default EInvoiceSidebarIcon;