import React from "react";

const DropdownSidebarIcon = (props) => { 
    const color = props.color ? props.color : 'white'
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 10L12 13L15 10" stroke={color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default DropdownSidebarIcon;