import React from 'react'

import { deniedLogin, blockedLogin } from '../../assets/images'

import './failed-login-styles.css'

const FailedLogin = (props) => {
    const isDenied = props.isDenied
    const handleClick = props.handleClick ? props.handleClick : () => {}
    const titleText = isDenied ? 'Access Denied' : 'You are not authorized';
    const detailText1 = isDenied ? 'You do not have access to the application.' : 'It seems like you don’t have permission to use this portal.'
    const detailText2 = isDenied ? 'Please contact the admin.' : 'Please contact the admin or try login in different account.'
    const btnText = isDenied ? 'Back to Home' : 'Back to Login Page'
    
    return (
        <div className='container-outer'>
            <div className='failed-container'>
                <img 
                    src={isDenied ? deniedLogin : blockedLogin}
                    width="160px"
                    height="160px"
                />
                <h1 className='title-text'>{titleText}</h1>
                <p className='detail-text'>{detailText1}</p>
                <p className='detail-text'>{detailText2}</p>
                <div className="btn-container-failed" onClick={handleClick}>
                    <h1 className="btn-failed-text">
                        {btnText}
                    </h1>
                </div>
            </div>
        </div>
    )
}

export default FailedLogin;