import React, { useEffect, useState } from "react";
import Datepicker from "react-tailwindcss-datepicker"; 

import Sidebar from "../../components/Sidebar/Sidebar.component";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb.component";
import SearchBar from "../../components/Searchbar/Searchbar.component";
import Chart from "../../components/Chart/Chart.component";
import MonitorBox from "./MonitorBox";
import './styles.css'
import NoAccessItem from "../../components/NoAccessItem/NoAccessItem.component";
import { chartNoaccess, dataNoaccess } from "../../assets/images";
import PendingTask from "./PendingTask";
import { useAppDispatch } from "../../redux/store";
import { setOpenSubmenu } from "../../redux/menuReducer";

const Dashboard = (props) => {
   const breadCrumbData = [
      {
         name: 'Home',
         path: '/dashboard',
         isLastIndex: false
      },
      {
         name: 'Dashboard',
         path: '/dashboard',
         isLastIndex: true
      }
   ]
   const searchData = [
      'SMF', 'LMS', 'DATABASE'
   ];
   const amountData = [
      '300,000', '190,000', '510', '490,510'
   ]
   const totalData = [
      'RM37.5K', 'RM1,100', 'RM2,508', 'RM41.1K'
   ]
   const chartData = [
      {
          name: 'SAP',
          completed: 8000,
          reject: 8000,
          failed: 2400,
          amt: 100,
      },
      {
          name: 'LMS',
          completed: 3000,
          reject: 4000,
          failed: 1398,
          amt: 2210,
      },
      {
          name: 'SQL',
          completed: 2000,
          reject: 3000,
          failed: 9800,
          amt: 2290,
      },
      {
          name: 'SAGE',
          completed: 2780,
          reject: 4000,
          failed: 3908,
          amt: 2000,
      }
  ]
  const inboxData = [
      {
         title: "Invoice - Request approval for modifications",
         date: "11 Jan 2024",
         isUnread: true
      },
      {
         title: "Role Management - Request approval for modifications",
         date: "11 Jan 2024",
         isUnread: false
      },
      {
         title: "Invoice - Request approval for creation",
         date: "11 Jan 2024",
         isUnread: true
      },
      {
         title: "User Management - Modification approved",
         date: "11 Jan 2024",
         isUnread: false
      }
  ]
  const noAccessData = false
   const dispatch = useAppDispatch()
   const [activeTab, setActiveTab] = useState(1)
   const [value, setValue] = useState({ 
      startDate: null,
      endDate: null 
   }); 

   useEffect(() => {
      dispatch(setOpenSubmenu({
          openSubmenu: '0'
      }))
  }, [])

   const handleValueChange = (newValue) => {
      setValue(newValue); 
   } 

    return (
      <>
         <Sidebar
            currentLocation="/dashboard"
            indexActive="0"
         />
         <div className="main-content-container mr-5">
            <div className="flex flex-row justify-between">
               <div>
                  <Breadcrumb data={breadCrumbData}></Breadcrumb>
                  <h1 className="main-title">Dashboard</h1>
               </div>
               <div className="py-5">
                  <div className="flex flex-row button-tab-container content-center py-3 justify-evenly px-2">
                     <div className={`cursor-pointer ${activeTab == 1 && 'active-container'} mr-2`} onClick={() => setActiveTab(1)}>
                        <h1 className={`inactive-text ${activeTab == 1 && 'active-text'}`}>Show Number</h1>
                     </div>
                     <div className={`cursor-pointer ${activeTab == 2 && 'active-container'} ml-2`} onClick={() => setActiveTab(2)}>
                        <h1 className={`inactive-text ${activeTab == 2 && 'active-text'}`}>Show Amount</h1>
                     </div>
                  </div>
               </div>
            </div>
            <div className="flex flex-row w-full">
               <SearchBar data={searchData}></SearchBar>
               <div className="w-4/12 py-4">
                  <Datepicker
                     disabled={noAccessData ? true : false}
                     useRange={false} 
                     value={value}
                     primaryColor={"orange"}
                     onChange={handleValueChange}
                     displayFormat={"DD-MM-YYYY"}
                     maxDate={new Date()}
                     placeholder="Choose date"
                  />
               </div>
            </div>
            <MonitorBox isNoaccessData={noAccessData} activeTab={activeTab} amountData={amountData} totalData={totalData} />
            <div className="flex flex-row mt-10" style={{height: '40vh'}}>
               {
                  noAccessData ? (
                     <NoAccessItem
                        styleContainer={{
                           boxShadow: "0px 5px 20px 10px #5977C21A",
                           borderRadius: "8px"
                        }}
                        imageUrl={chartNoaccess} 
                        detailText="No access to chart"></NoAccessItem>
                  ) : (
                     <Chart data={chartData}></Chart>
                  )
               }
               <div className="w-6/12 ml-10 inbox-container">
                  {
                     noAccessData ? (
                        <NoAccessItem imageHeight="140px" imageWidth="140px" imageUrl={dataNoaccess} detailText="No access to data"></NoAccessItem>
                     ) : (
                        <>
                           <div className="flex flex-row justify-between pt-5 pl-7 pr-5">
                              <div className="flex flex-row">
                                 <h1 className="inbox-title-text">Pending Task</h1>
                                 {/* <p className="inbox-notif-text">4 new</p> */}
                              </div>
                              <h1 className="all-message-text">View All</h1>
                           </div>
                           <div className="first-inbox-container"></div>
                              <div className="pl-5 pr-5">
                                 <PendingTask />
                              </div>
                           {/* inbox component */}
                           {/* <div className="pl-5 pr-5 overflow-y-scroll scroll-auto">
                              {inboxData.map((data) => (
                                 <InboxItem data={data} />
                              ))}
                           </div> */}
                        </>
                     )
                  }

               </div>
            </div>
         </div>

      </>
   );
}

export default Dashboard;