import React, { useState, useEffect } from 'react';

import { DropdownSearchbarIcon, closeButtonWithCircle, EInvoiceFilterIcon } from '../../../assets/images';
import TextView from '../../../components/TextView';
import './styles.css'

const dataStatus = [
    {
        name: 'Approved',
        status: false
    }, 
    {
        name: 'Rejected',
        status: false
    },
    {
        name: 'Pending',
        status: false
    }
]

const SearchBar = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [submenuIndex, setSubmenuIndex] = useState(0);
  const [showSubmenu, setShowSubmenu] = useState(false)
  const [tags, setTags] = useState([])
 const [checkSource, setCheckSource] = useState(false);
 const [inputtedSource, setInputtedSource] = useState([]);
 const [checkStatus, setCheckStatus] = useState(false)
 const [inputtedStatus, setInputtedStatus] = useState(dataStatus);
 const [checkEntity, setCheckEntity] = useState(false)
 const [inputtedEntity, setInputtedEntity] = useState([]);
 const [filterCount, setFilterCount] = useState(0)
 const dataSearchbarMenu = [
    {
        label: 'Select Option',
        value: null
    },
    {
        label: 'Email',
        value: 'email'
    },
    {
        label: 'User Name',
        value: 'username'
    }
  ]
  const dataEntity = [
    'AFX-A', 'AFX-B'
  ]
  const dataSource = [
    'SAP', 'LMS', 'SQL', 'SAGE'
  ]
  useEffect(() => {
    handleCountChanged()
  }, [checkSource, checkStatus, checkEntity])

    const handleCheckedSource = () => {
        setInputtedSource([])
        setCheckSource(!checkSource)
        handleCountChanged()
    }
    const handleCheckedStatus = () => {
        setCheckStatus(!checkStatus)
        handleCountChanged()
        return setInputtedStatus(dataStatus)
    }
    const handleCheckedType = () => {
        setCheckEntity(!checkEntity)
        handleCountChanged()
        return setInputtedEntity([])
    }

    const handleSourceChanged = (value) => {
        if (!checkSource) {
            return;
        }
        const index = inputtedSource.indexOf(value)
        let newArray = [...inputtedSource]
        if (index != -1) {
            newArray.splice(index, 1)
            setInputtedSource(newArray)
        } else {
            newArray.push(value)
            setInputtedSource(newArray)
        }
    }
    const handleStatusChanged = (value) => {
        if (!checkStatus) {
            return;
        }
        let newArrayName = inputtedStatus.map(status => status.name)
        const index = newArrayName.indexOf(value)
        const newArray = [...inputtedStatus]
        if (index != -1) {
            let newData = {
                name: value,
                status: !inputtedStatus[index].status
            }
            newArray.splice(index, 1, newData)
            setInputtedStatus(newArray)
        }
    }
    const handleEntityChanged = (value) => {
        if (!checkEntity) {
            return;
        }
        const index = inputtedEntity.indexOf(value)
        let newArray = [...inputtedEntity]
        if (index != -1) {
            newArray.splice(index, 1)
            setInputtedEntity(newArray)
        } else {
            newArray.push(value)
            setInputtedEntity(newArray)
        }
    }
    const handleCountChanged = () => {
        let countStart = 0
        if (checkSource) {
            countStart += 1
        }
        if (checkStatus) {
            countStart += 1
        }
        if (checkEntity) {
            countStart += 1
        }
        setFilterCount(countStart)
    }

  const handleClose = (input) => {
    const index = tags.indexOf(input)
    const newArray = [...tags]
    if (index != -1) {
        newArray.splice(index, 1)
        setTags(newArray)
    }
  }

  const handleSubmenuItem = (index) => {
    if (index == 0) {
        return;
    }
    setSubmenuIndex(index)
    setShowSubmenu(false)
    const elem = document.activeElement;
    if(elem){
      elem?.blur();
    }
  }

  const handleKeyPress = (e) => {
    if (e.key == 'Enter') {
        handleClick()
    }
  }

  const handleChange = (e) => {
    const input = e.target.value;
    setSearchTerm(input);
  };

  const checkTagsAvailable = (index) => {
    const foundTags = dataSearchbarMenu[index].value
    const foundRegex = new RegExp(foundTags)
    if (foundRegex.test(tags.join())) {
        return false;
    }
    return true
  }

  const handleClick = () => {
    if (submenuIndex == 0 || searchTerm == '') {
        return
    }
    const isTagsAvailable = checkTagsAvailable(submenuIndex)
    if (!isTagsAvailable) {
        return
    }
    setSearchTerm('')
    const newTag = `${dataSearchbarMenu[submenuIndex].value}:${searchTerm}`
    const newTags = [...tags]
    newTags.push(newTag)
    setTags(newTags)
  }

  const handleReset = () => {
    setCheckSource(false)
    setCheckStatus(false)
    setCheckEntity(false)
    setFilterCount(0)
    setInputtedStatus(dataStatus)
    setInputtedEntity([])
    setInputtedSource([])
  }

  const handleCancel = () => {

  }

  const handleApply = () => {

  }

  return (
    <div className='flex flex-col mb-30'>
    <div className="py-4 w-full flex flex-row">
        <div className='relative w-10/12'>
            <div className='flex flex-row absolute left-0 searchbar-title-einvoice-container pr-2 h-10 w-64 justify-between content-between'>
                <button type='button' onClick={() => setShowSubmenu(!showSubmenu)} aria-expanded="false" className='button-text flex flex-auto'
                >
                    <span className='active-submenu-item'>{dataSearchbarMenu[submenuIndex].label}</span>
                </button>
                <DropdownSearchbarIcon 
                    color="#667085" 
                    containerStyle={
                        {
                            marginTop: '8px'
                        }
                    }
                />                
            </div>

            <div className='flex flex-row pr-2 rounded-md border w-11/12  border-gray-300'>
                <input
                    type="text"
                    placeholder={tags.length == 0 && "Search..."}
                    value={searchTerm}
                    onChange={handleChange}
                    onKeyDown={handleKeyPress}
                    className="pl-72 pr-4 py-2 w-full border-none"
                />
                <div className="cursor-pointer left-icon" onClick={handleClick}>
                    <h1 className='searchbar-add-text'>Add</h1>
                </div>
            </div>
            <div id="einvoice-search-menu-dropdown" className={`absolute z-10 ${!showSubmenu ? 'hidden': ''} bg-white divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="einvoice-menu-dropdown">
                    {
                        dataSearchbarMenu.map((subItem, index) => (
                            <li>
                                {
                                    index > 0 && (
                                    <a 
                                        href="#" 
                                        onClick={() => handleSubmenuItem(index)} 
                                        className={`${!checkTagsAvailable(index) && 'disabled-pointer'} block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white einvoice-search-submenu-text`}
                                    >
                                            {subItem.label}
                                    </a>
                                    )
                                }
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
        <div className='w-2/12'>
            <button className="w-full flex flex-row justify-center text-orange  outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" type="button" data-drawer-target="drawer-right-example" data-drawer-show="drawer-right-example" data-drawer-placement="right" aria-controls="drawer-right-example">
                <EInvoiceFilterIcon />
                <span className='filter-text-menu'>Filter</span>
                {
                    filterCount > 0 && (<p className='filter-text-count'>{filterCount}</p>)
                }
            </button>
        </div>
<div id="drawer-right-example" className="fixed top-0 right-0 z-40 h-screen transition-transform translate-x-full overflow-y-auto bg-white w-96 dark:bg-gray-800" tabindex="-1" aria-labelledby="drawer-right-label">
    <div className='flex flex-row pt-4 px-4'>
    <h5 id="drawer-right-label" className="inline-flex items-center mb-4 text-base font-semibold text-gray-500 dark:text-gray-400">
        Filter</h5>
        {
            filterCount > 0 && (<p className='filter-text-count'>{filterCount}</p>)
        }
    <button onClick={() => handleReset()} disabled={filterCount > 0 ? false : true} type='button' className={`text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-20 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white ${filterCount > 0 && 'orange-text'}`} >
        <h5 className='reset-all-text '>Reset All</h5>
    </button>
    </div>
   <div className='border-b m-0 p-0'></div>
    <div className='p-4'>
    <div className="flex items-center mb-4 mt-4">
            <input 
                id="default-checkbox" 
                type="checkbox" 
                checked={checkEntity}
                value={checkEntity}
                style={{
                    color: '#EC4A0A'
                }}
                onChange={handleCheckedType}
                className="w-4 h-4 rounded accent-orange-400 "/>
            <label for="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Entity</label>
        </div>
        <div className="w-full py-4 border-b flex flex-row">
            {
                dataEntity.map((entity, index) => (
                    <button onClick={() => handleEntityChanged(entity)} className={`${checkEntity && 'source-item-non-chosen-container'} border cursor-pointer p-4 source-item-container ${inputtedEntity.indexOf(entity) > -1 && 'source-item-active-container'} ml-3`}>
                        <TextView text={entity} textStyles={{
                            color: inputtedEntity.indexOf(entity) > -1 ? "#EC4A0A" : checkEntity ? "#667085": '#D0D5DD'
                        }}></TextView>
                    </button>
                ))
            }
        </div>
        <div className="flex items-center mb-4 mt-4">
            <input 
                id="default-checkbox" 
                type="checkbox" 
                checked={checkSource}
                value={checkSource}
                style={{
                    color: '#EC4A0A'
                }}
                onChange={handleCheckedSource}
                className="w-4 h-4 rounded accent-orange-400 "/>
            <label for="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Source System</label>
        </div>
        <div className="w-full py-4 border-b flex flex-row">
            {
                dataSource.map((source, index) => (
                    <button onClick={() => handleSourceChanged(source)} className={`${checkSource && 'source-item-non-chosen-container'} border cursor-pointer p-4 source-item-container ${inputtedSource.indexOf(source) > -1 && 'source-item-active-container'} ml-3`}>
                        <TextView text={source} textStyles={{
                            color: inputtedSource.indexOf(source) > -1 ? "#EC4A0A" : checkSource ? "#667085": '#D0D5DD'
                        }}></TextView>
                    </button>
                ))
            }
        </div>

        <div className="flex items-center mb-4 mt-4">
            <input 
                id="default-checkbox" 
                type="checkbox"
                checked={checkStatus}
                value={checkStatus}
                style={{
                    color: '#EC4A0A'
                }}
                onClick={handleCheckedStatus}
                className="w-4 h-4 rounded accent-orange-400 "/>
            <label for="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Approval Status</label>
        </div>
        <div className="flex max-h-40 border-b flex-wrap ml-2">
            {
                inputtedStatus.map(({name, status}, index) => (
                    <div className="flex items-center w-40 mb-5">
                        <input
                            disabled={checkStatus ? false : true}
                            id="default-checkbox" 
                            type="checkbox"
                            checked={status}
                            value={status}
                            style={{
                                color: '#EC4A0A'
                            }}
                            onClick={() => handleStatusChanged(name)}
                            className={`w-4 h-4 rounded accent-orange-400 ${!status ? 'bg-gray-100' : 'bg-white'}`}/>
                        <label for="default-checkbox" className="w-full ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">{name}</label>
                    </div>
                ))
            }
        </div>
    </div>
    <div className='fixed bottom-10 right-10 flex flex-row items-center justify-center align-middle'>
        <div onClick={handleCancel} className='cursor-pointer filter-button-container' data-drawer-hide="drawer-right-example" aria-controls="drawer-right-example">Cancel</div>
        <div onClick={handleApply} className='cursor-pointer filter-button-container apply-button-container' data-drawer-hide="drawer-right-example" aria-controls="drawer-right-example">Apply</div>
    </div>
    </div>
    </div>
    {
        tags.length > 0 && (
        <>
            <div className='absolute top-40 flex flex-row pt-2'>
            {tags.map(tag => (
                <div className='flex flex-row chosen-tag'>
                    <p className='chosen-tag-name'>{tag}</p>
                    <img className='cursor-pointer mb-1' onClick={() => handleClose(tag)} src={closeButtonWithCircle} width="20px" height="20px"/>
                </div>   
            ))}
            </div>
        </>)
    }
    </div>

  );
};

export default SearchBar;