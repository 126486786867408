import React from "react";

import './styles.css'

const LegendItem = (props) => {
    const bgColor = props.bgColor ? props.bgColor: 'red'
    return (
        <div className="flex flex-col justify-start content-start">
            <div className="flex flex-row">
                <div style={{
                    backgroundColor: bgColor
                }}
                className="mr-2 rounded-full legend-symbol mt-2">
                </div>
                <h1 className="legend-item-text"
                style={{color: bgColor}}>{props.legendText}</h1>
            </div>
            <h1 className="legend-item-count-text">{props.count ? props.count.toLocaleString() : '-'}</h1>
        </div>
    );
}

export default LegendItem;