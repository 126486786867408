import React, {useState} from 'react'
import {
    PieChart, Pie, Sector, ResponsiveContainer, Cell 
} from 'recharts'

import './pie-chart-styles.css'

const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
    } = props
    const sin = Math.sin(-RADIAN * midAngle)
    const cos = Math.cos(-RADIAN * midAngle)
    const sx = cx + (outerRadius + 10) * cos
    const sy = cy + (outerRadius + 10) * sin
    const mx = cx + (outerRadius + 10) * cos
    const my = cy + (outerRadius + 10) * sin
    const ex = mx + (cos >= 0 ? 1 : -1) * 22
    const ey = my
    const textAnchor = cos >= 0 ? 'start' : 'end'

    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill="none"
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 5}
                y={ey}
                textAnchor={textAnchor}
                width={5}
                fill="#333"
            >{`${payload.name}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1)}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill="#999"
            >
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        </g>
    )
}

const Chart = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)

    const onPieEnter = (index) => {
        setActiveIndex(index)
    }

    return (
        <ResponsiveContainer width="100%" height="80%">
            <PieChart width={400} height={400}>
                <Pie
                    activeIndex={activeIndex}
                    // activeShape={renderActiveShape}
                    data={props.data}
                    cx="50%"
                    cy="50%"
                    innerRadius={50}
                    outerRadius={90}
                    fill="#8884d8"
                    dataKey="value"
                    // onMouseEnter={onPieEnter}
                >
                    {props.data.map((_entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={props.colors[index % props.colors.length]}
                            data-testid={`pie-slice-${index}`}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    )
}

export default Chart
