import React, { useState } from "react";

import { eInvoiceDownloadIcon, eInvoiceEyeIcon } from "../../../assets/images";
import Pagination from "../../../components/Pagination";
import { renderTextBackgroundApprovalStatus } from "../../../utils/stringUtils";
import TextView from "../../../components/TextView";
import './styles.css'

const Table = () => {
    const [currentPage, setPage] = useState({
        'page-size': 5,
        'current-page': 0,
    })
    const handlePageChange = (value) => {
        setPage(value)
    }
    const handleSizeChange = (value) => {
        setPage(value)
    }

    const TABLE_HEAD = [
        'User Name',
        'Email',
        'Approval Status',
        'Action'
    ]
    const TABLE_DATA = [
        {
            userName: 'Alex',
            email: 'alex@mail.com',
            approvalStatus: 'Approved'
        },
        {
            userName: 'Ruddy',
            email: 'ruddy@mail.com',
            approvalStatus: 'Rejected'
        },
        {
            userName: 'Alice',
            email: 'alice@mail.com',
            approvalStatus: 'Pending'
        },
        {
            userName: 'Tobby',
            email: 'tobby@mail.com',
            approvalStatus: 'Approved'
        },
        {
            userName: 'Sasha',
            email: 'sasha@mail.com',
            approvalStatus: 'Rejected'
        },
        {
            userName: 'Mark',
            email: 'mark@mail.com',
            approvalStatus: 'Pending'
        },
        {
            userName: 'Lucy',
            email: 'lucy@mail.com',
            approvalStatus: 'Rejected'
        }
    ]

    return (
        <>
            <div className="relative h-full w-full">
                <table className="w-full table-auto text-left">
                    <thead>
                        <tr className="flex flex-row content-evenly w-full justify-evenly">
                            {
                                TABLE_HEAD.map((column, index) => (
                                    <th
                                        key={index}
                                        className="w-full text-gray-700 bg-gray-50 border-b border-blue-gray-100 bg-blue-gray-50 pt-4 mt-3 pl-5"
                                    >
                                        <h1
                                            variant="small"
                                            color="blue-gray"
                                            className="table-head-text font-normal leading-none opacity-70 pb-4"
                                        >
                                            {column}
                                        </h1>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                           TABLE_DATA.map((data, index) => (
                                <tr className="flex flex-row content-evenly w-full justify-evenly">
                                    <td className="w-full p-3 border-b border-blue-gray-50 flex flex-row">
                                        <span className="pr-2 submenu-text bottom-initial-container-sidebar">B</span>
                                        <TextView classStyle="pt-2 ml-2" text={data.userName}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-2 ml-3" textStyles={{
                                            textAlign: 'left'
                                        }} text={data.email}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-1 ml-2" textStyles={renderTextBackgroundApprovalStatus(data.approvalStatus)} text={data.approvalStatus}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50 flex flex-row content-center">
                                        <img src={eInvoiceEyeIcon}></img>
                                        <img className="border-l border-white" src={eInvoiceDownloadIcon}></img>
                                    </td>
                                </tr>
                           )) 
                        }
                    </tbody>
                </table>
                <Pagination
                    value={currentPage}
                    onPageChange={e => handlePageChange(e)}
                    onSizeChange={e => handleSizeChange(e)}
                    totalPage={1}
                    totalData={TABLE_DATA.length}
                />
            </div>
        </>
    )
}

export default Table