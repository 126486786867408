import { createSlice } from "@reduxjs/toolkit"

export const initialState = {
  isAuthenticated: false,
  token: null,
  refreshToken: null,
  userPermissions: [],
  userAccessManagement: []
}

const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    authenticatedUser: (state, action) => {
      Object.assign(state, {
        ...state,
        ...action.payload
      })
    },
    logOutApps() {}
  }
})

export const { authenticatedUser, logOutApps } = authenticationSlice.actions
export default authenticationSlice.reducer
