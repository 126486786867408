import React from "react";

import './main-container-styles.css'

const MainContainer = (props) => {
    return (
        <div className="w-5/12 main-container" style={{height: '40vh', ...props.containerStyle}}>
            {props.children}
        </div>
    )
}

export default MainContainer