import React from "react";
import { useNavigate } from "react-router-dom";

import FailedLogin from "../../components/Login/FailedLogin.component";

const Unauthorized = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/login', { replace: true })
    }

    return (<FailedLogin handleClick={handleClick} />)
}

export default Unauthorized;