import React from "react";

const EInvoiceArrowRightIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : '#667085'
    
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" className="mt-2 ml-3 w-5 h-5 transition duration-75 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M1 9L5 5L1 1" stroke={filledColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default EInvoiceArrowRightIcon;