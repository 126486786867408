import React from "react";

import {
    blueBackgroundDashboard, greenBackgroundDashboard,
    greenMonitorTotalDashboard,greenMonitorAmountDashboard,
    orangeBackgroundDashboard, redBackgroundDashboard,
    orangeMonitorAmountDashboard, orangeMonitorTotalDashboard,
    redMonitorAmountDashboard, redMonitorTotalDashboard,
    blueMonitorAmountDashboard, blueMonitorTotalDashboard,
    arrowExternalDashboard, dataNoaccess
} from '../../../assets/images'
import NoAccessItem from "../../../components/NoAccessItem/NoAccessItem.component";
import './styles.css'

const MonitorBox = (props) => {
    const { activeTab, amountData, totalData } = props
    const noAccessData = props.noAccessData

    return (
        <>
        <div className="flex flex-row justify-between">
            {
               noAccessData ? (
                
                    totalData.map(index => (
                        <NoAccessItem 
                            styleContainer={{
                                width: "271px",
                                height: "154px",
                                boxShadow: "0px 5px 20px 10px #5977C21A",
                                borderRadius: "8px"
                            }} 
                            imageUrl={dataNoaccess} 
                            imageHeight="70px" 
                            imageWidth="70px" 
                            detailText="No access to data" 
                        />
                    ))
               ) : (
                <>
                <div 
                    style={{
                    background: `url(${greenBackgroundDashboard})`,
                    backgroundPosition: 'center',
                    backgroundRepeat:'no-repeat',
                    backgroundSize:'100%'
                }} 
                className="default-size"
                >
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                            <img src={activeTab == 1 ? greenMonitorTotalDashboard :  greenMonitorAmountDashboard } width="60px" height="60px" />
                            <div className="flex flex-col py-2 ml-3">
                                <p className="text-monitor">Total Invoice Completed</p>
                            </div>
                        </div>
                        <img className="py-4 mr-4" src={arrowExternalDashboard} max-width="24px" max-height="24px" />
                    </div>
                    <div className="flex-inline-y inset-x-full mt-2">
                        <h1 className="text-data">{activeTab == 1 ? amountData[0] : totalData[0] }</h1>
                    </div>
                </div>
                <div 
                    style={{
                        background: `url(${orangeBackgroundDashboard})`,
                        backgroundPosition: 'center',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'100%'
                    }} 
                    className="default-size"
                >
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                            <img src={activeTab == 1 ? orangeMonitorTotalDashboard :  orangeMonitorAmountDashboard } width="60px" height="60px" />
                            <div className="flex flex-col py-2 ml-3">
                                <p className="text-monitor">Total Rejected by Buyer</p>
                            </div>
                        </div>
                        <img className="py-4 mr-4" src={arrowExternalDashboard} max-width="24px" max-height="24px" />
                    </div>
                    <div className="flex-inline-y inset-x-full mt-2">
                        <h1 className="text-data">{activeTab == 1 ? amountData[1] : totalData[1] }</h1>
                    </div>
                </div>
                <div 
                    style={{
                        background: `url(${redBackgroundDashboard})`,
                        backgroundPosition: 'center',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'100%'
                    }} 
                    className="default-size"
                >
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                            <img src={activeTab == 1 ? redMonitorTotalDashboard :  redMonitorAmountDashboard } width="60px" height="60px" />
                            <div className="flex flex-col py-2 ml-3 mr-3">
                                <p className="text-monitor">Total failed integrated to IRB</p>
                            </div>
                        </div>

                        <img className="py-4 mr-4" src={arrowExternalDashboard} max-width="24px" max-height="24px" />
                    </div>
                    <div className="flex-inline-y inset-x-full mt-2">
                        <h1 className="text-data">{activeTab == 1 ? amountData[2] : totalData[2] }</h1>
                    </div>
                </div>
                <div
                    style={{
                        background: `url(${blueBackgroundDashboard})`,
                        backgroundPosition: 'center',
                        backgroundRepeat:'no-repeat',
                        backgroundSize:'100%'
                    }} 
                    className="default-size"
                >
                    <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                            <img src={activeTab == 1 ? blueMonitorTotalDashboard :  blueMonitorAmountDashboard } width="60px" height="60px" />
                            <div className="flex flex-col py-5 ml-3">
                                <p className="text-monitor">Total Invoice</p>
                            </div>
                        </div>
                        <img className="py-4 mr-4" src={arrowExternalDashboard} max-width="24px" max-height="24px" />
                    </div>
                    <div className="flex-inline-y inset-x-full mt-2">
                        <h1 className="text-data">{activeTab == 1 ? amountData[3] : totalData[3] }</h1>
                    </div>
                </div>
                </>
               )
            }

        </div>
        </>
    )
}

export default MonitorBox