import React from "react";

const BatchUploadSidebarIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : 'white'
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M18.19 20H14.6342H13.6767H13.47V15.2328H15.0298C15.4254 15.2328 15.6592 14.7835 15.4254 14.46L12.3911 10.2634C12.1978 9.99382 11.7977 9.99382 11.6044 10.2634L8.57009 14.46C8.33633 14.7835 8.56559 15.2328 8.96567 15.2328H10.5255V20H10.3188H9.36126H5.23907C2.87904 19.8697 1 17.6636 1 15.2732C1 13.6243 1.89456 12.1865 3.22068 11.4092C3.09931 11.0812 3.03637 10.7307 3.03637 10.3623C3.03637 8.67734 4.39845 7.31592 6.08418 7.31592C6.4483 7.31592 6.79894 7.37883 7.12709 7.50014C8.10257 5.43331 10.2064 4 12.6518 4C15.8165 4.00449 18.4238 6.42629 18.7205 9.51306C21.1524 9.93092 23 12.182 23 14.7296C23 17.4524 20.8782 19.8113 18.19 20Z" fill={filledColor}/>
        </svg>
    )
}

export default BatchUploadSidebarIcon;