import React from "react";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import "./styles.css"

const PendingTask = (props) => {
    return (
        <div className="mt-10 ml-5 mr-5 flex flex-row content-center">
            <div style={{ width: '156px', height: '156px' }}>
                <CircularProgressbar 
                    value={50} 
                    text="50%"
                    styles={buildStyles({
                        textColor: '#FB6514',
                        trailColor: '#EAECF0',
                        pathColor: '#FB6514',
                        textSize: '24px'
                    })}
                />
            </div>
            <div className="flex flex-col content-center justify-center ml-4">
                <div className="flex flex-row">
                    <h1 className="done-text">180</h1>
                    <h1 className="done-text total-task-text">/360</h1>
                </div>
                <h1 className="task-completed-text">Task Completed</h1>
            </div>
        </div>
    )
}

export default PendingTask