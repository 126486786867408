import React, { useState } from "react";

import { eInvoiceAutomaticIcon, eInvoiceDownloadIcon, eInvoiceEyeIcon, eInvoiceManualIcon } from "../../../../assets/images";
import Pagination from "../../../../components/Pagination";
import { formatDate } from "../../../../utils/dateUtils";
import { renderTextColor, renderTextBackgroundInvoiceStatus } from "../../../../utils/stringUtils";
import TextView from "../../../../components/TextView";
import './styles.css'

const TableEInvoice = () => {
    const [currentPage, setPage] = useState({
        'page-size': 5,
        'current-page': 0,
    })
    const handlePageChange = (value) => {
        setPage(value)
    }
    const handleSizeChange = (value) => {
        setPage(value)
    }

    const TABLE_HEAD = [
        'Buyer Name',
        'Invoice No.',
        'IRBM Unique No.',
        'Document Dates',
        'Invoice Status',
        'e-Invoice Type',
        'Action',
    ]
    const TABLE_DATA = [
        {
            buyerName: 'Alex',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '23 April 2024',
            InvoiceStatus: 'Invalid',
            eInvoiceType: 'Refund Note',
            form: 'auto',
        },
        {
            buyerName: 'Alex',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '23 March 2024',
            InvoiceStatus: 'Draft',
            eInvoiceType: 'Invoice',
            form: 'manual',
        },
        {
            buyerName: 'Alex',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '22 March 2024',
            InvoiceStatus: 'Rejected',
            eInvoiceType: 'Invoice',
            form: 'auto',
        },
        {
            buyerName: 'Alex',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '21 February 2024',
            InvoiceStatus: 'Valid',
            eInvoiceType: 'Invoice',
            form: 'auto',
        },
        {
            buyerName: 'Alex',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '20 February 2024',
            InvoiceStatus: 'Submitted',
            eInvoiceType: 'Invoice',
            form: 'manual',
        },
        {
            buyerName: 'Badu',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '23 January 2024',
            InvoiceStatus: 'Cancelled',
            eInvoiceType: 'Debit Note',
            form: 'auto',
        },
        {
            buyerName: 'Boney',
            invoiceNo: 'INV12345',
            IRBMuniqueNo: 'IRBM33445566',
            documentDate: '22 January 2024',
            InvoiceStatus: 'Failed',
            eInvoiceType: 'Refund Note',
            form: 'auto',
        }
    ]

    return (
        <>
            <div className="relative h-full w-full">
                <table className="w-full table-auto text-center">
                    <thead>
                        <tr className="flex flex-row content-evenly w-full justify-evenly">
                            {
                                TABLE_HEAD.map((column, index) => (
                                    <th
                                        key={index}
                                        className="w-full text-gray-700 bg-gray-50 border-b border-blue-gray-100 bg-blue-gray-50 pt-4 mt-3"
                                    >
                                        <h1
                                            variant="small"
                                            color="blue-gray"
                                            className="table-head-text font-normal leading-none opacity-70 text-center pb-4"
                                        >
                                            {column}
                                        </h1>
                                    </th>
                                ))
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                           TABLE_DATA.map((data, index) => (
                                <tr className="flex flex-row content-evenly w-full justify-evenly">
                                    <td className="w-full p-3 border-b border-blue-gray-50 flex flex-row">
                                        {
                                            data.form == 'auto' ? (
                                                <img src={eInvoiceAutomaticIcon}></img>
                                            ) : (
                                                <img src={eInvoiceManualIcon}></img>
                                            )
                                        }
                                        <TextView classStyle="pt-2 ml-2" text={data.buyerName}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-2" text={data.invoiceNo}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-2" text={data.IRBMuniqueNo}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-2" text={formatDate(data.documentDate)}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-1 ml-3" textStyles={renderTextBackgroundInvoiceStatus(data.InvoiceStatus)} text={data.InvoiceStatus}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50">
                                        <TextView classStyle="pt-2" textStyles={renderTextColor(data.eInvoiceType)} text={data.eInvoiceType}></TextView>
                                    </td>
                                    <td className="w-full p-3 border-b border-blue-gray-50 flex flex-row content-center justify-center">
                                        <img src={eInvoiceEyeIcon}></img>
                                        <img className="border-l border-white" src={eInvoiceDownloadIcon}></img>
                                    </td>
                                </tr>
                           )) 
                        }
                    </tbody>
                </table>
                <Pagination
                    value={currentPage}
                    onPageChange={e => handlePageChange(e)}
                    onSizeChange={e => handleSizeChange(e)}
                    totalPage={1}
                    totalData={TABLE_DATA.length}
                />
            </div>
        </>
    )
}

export default TableEInvoice