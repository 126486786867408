import { combineReducers } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'

import authenticationReducer from './authenticationReducer'
import menuReducer from './menuReducer'

const rootReducer = combineReducers({
    authenticationReducer,
    menuReducer
})

const applicationRootReducer = (state, action) => {
    if (action.type === "authentication/logOutApps") {
      storage.removeItem("persist:root")
  
      state = {}
    }
    return rootReducer(state, action)
}
  
export default applicationRootReducer