import React from "react";

const DashboardSidebarIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : 'white'
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M4.26562 11.3125C4.78561 11.3125 5.25626 11.1039 5.60403 10.7688L7.51195 11.7227C7.50238 11.8004 7.48828 11.8768 7.48828 11.957C7.48828 13.0232 8.35571 13.8906 9.42188 13.8906C10.488 13.8906 11.3555 13.0232 11.3555 11.957C11.3555 11.6594 11.2823 11.3808 11.1616 11.1287L13.7498 8.54051C14.0019 8.66119 14.2805 8.73438 14.5781 8.73438C15.6443 8.73438 16.5117 7.86694 16.5117 6.80078C16.5117 6.60004 16.4723 6.4102 16.4152 6.22809L18.664 4.5419C18.9708 4.74684 19.3386 4.86719 19.7344 4.86719C20.8005 4.86719 21.668 3.99976 21.668 2.93359C21.668 1.86743 20.8005 1 19.7344 1C18.6682 1 17.8008 1.86743 17.8008 2.93359C17.8008 3.13434 17.8402 3.32417 17.8973 3.50629L15.6485 5.19247C15.3417 4.98753 14.9739 4.86719 14.5781 4.86719C13.512 4.86719 12.6445 5.73462 12.6445 6.80078C12.6445 7.09837 12.7177 7.377 12.8384 7.6291L10.2502 10.2173C9.99809 10.0966 9.71947 10.0234 9.42188 10.0234C8.90189 10.0234 8.43124 10.2321 8.08347 10.5671L6.17555 9.61322C6.18512 9.53551 6.19922 9.45914 6.19922 9.37891C6.19922 8.31274 5.33179 7.44531 4.26562 7.44531C3.19946 7.44531 2.33203 8.31274 2.33203 9.37891C2.33203 10.4451 3.19946 11.3125 4.26562 11.3125Z" fill={filledColor}/>
            <path d="M22.3555 21.7109H21.668V8.08984C21.668 7.73367 21.3796 7.44531 21.0234 7.44531H18.4453C18.0891 7.44531 17.8008 7.73367 17.8008 8.08984V21.7109H16.5117V11.957C16.5117 11.6009 16.2234 11.3125 15.8672 11.3125H13.2891C12.9329 11.3125 12.6445 11.6009 12.6445 11.957V21.7109H11.3555V17.1133C11.3555 16.7571 11.0671 16.4688 10.7109 16.4688H8.13281C7.77664 16.4688 7.48828 16.7571 7.48828 17.1133V21.7109H6.19922V14.5352C6.19922 14.179 5.91086 13.8906 5.55469 13.8906H2.97656C2.62039 13.8906 2.33203 14.179 2.33203 14.5352V21.7109H1.64453C1.28836 21.7109 1 21.9993 1 22.3555C1 22.7116 1.28836 23 1.64453 23H22.3555C22.7116 23 23 22.7116 23 22.3555C23 21.9993 22.7116 21.7109 22.3555 21.7109Z" fill={filledColor}/>
        </svg>
    )
}

export default DashboardSidebarIcon;