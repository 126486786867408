import React from "react";

const EInvoiceDoubleArrowLeftIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : '#D0D5DD'
    
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" className="mt-2 ml-2 w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M1.26986 3.64644L0.916303 3.99999L1.26986 4.35355L4.1223 7.20598C4.18953 7.27321 4.18953 7.38235 4.1223 7.44958L4.47586 7.80313L4.1223 7.44958C4.05507 7.51681 3.94593 7.51681 3.8787 7.44958L0.5506 4.12146L0.197046 4.47501L0.550599 4.12146C0.483125 4.05399 0.48376 3.9452 0.550158 3.87897L0.550599 3.87853L3.8787 0.550422C3.94593 0.483192 4.05507 0.483192 4.1223 0.550422C4.18953 0.617652 4.18953 0.726791 4.12231 0.794019L1.26986 3.64644ZM4.56143 4.3324L4.56142 4.33239C4.47886 4.24538 4.47915 4.09464 4.56121 4.00826L4.56143 4.00803L7.20345 1.22345C7.24086 1.18407 7.28326 1.16947 7.32146 1.16947C7.35966 1.16947 7.40206 1.18407 7.43947 1.22345C7.52164 1.30995 7.52164 1.46087 7.43947 1.54738L7.43926 1.54759L5.27737 3.82606L4.95083 4.17022L5.27737 4.51437L7.43926 6.79284L7.43926 6.79285C7.52182 6.87985 7.52153 7.0306 7.43947 7.11698C7.40207 7.15635 7.35966 7.17096 7.32146 7.17096C7.28328 7.17096 7.24091 7.15637 7.20352 7.11706C7.20349 7.11703 7.20347 7.117 7.20344 7.11698L4.56143 4.3324Z" fill="white" stroke={filledColor} />
        </svg>
    )
}

export default EInvoiceDoubleArrowLeftIcon;