import React from 'react';
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import store, { persistor } from './redux/store'

import AppRoutes from './routes';

const config = {
  auth: {
      clientId: process.env.REACT_APP_VITE_CLIENT_ID,
      authority: process.env.REACT_APP_VITE_TENANT_ID,
      redirectUri: process.env.REACT_APP_VITE_REDIRECT_URL,
      verify: false,
  },
}

function App() {
  const pca = new PublicClientApplication(config)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MsalProvider instance={pca}>
          <AppRoutes />
        </MsalProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
