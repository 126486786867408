import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux'

import { useAppDispatch } from "../../redux/store";
import { 
    logoSidebar, DashboardSidebarIcon,
    EInvoiceSidebarIcon, ReportSidebarIcon,
    InboxSidebarIcon, ManagementSidebarIcon,
    LogSidebarIcon, DropdownSidebarIcon,
    CircleSidebarIcon, logoBottomSidebar,
    tripleDotSidebar, BatchUploadSidebarIcon
 } from '../../assets/images'
import './sidebar-styles.css'
import { setOpenSubmenu } from "../../redux/menuReducer";

const Sidebar = (props) => {
   const [showEInvoiceSubmenu, setShowEInvoiceSubmenu] = useState(false)
   const [showBatchUploadSubmenu, setShowBatchUploadSubmenu] = useState(false)
   const [showReportSubmenu, setShowReportSubmenu] = useState(false)
   const [showManagementSubmenu, setShowManagementSubmenu] = useState(false)
   const { openSubmenu } = useSelector((state) => state.menuReducer)
   const dispatch = useAppDispatch()
   const location = useLocation();
   const isLocationMatch = location.pathname.includes(props.currentLocation)

   useEffect(() => {
      if (openSubmenu == '1') {
         setShowEInvoiceSubmenu(true)
      }
      else if (openSubmenu == '6') {
         setShowBatchUploadSubmenu(true)
      }
      else if (openSubmenu == '2') {
         setShowReportSubmenu(true)
      }
      else if (openSubmenu == '4') {
         setShowManagementSubmenu(true)
      } else {
         setShowEInvoiceSubmenu(false)
         setShowBatchUploadSubmenu(false)
         setShowReportSubmenu(false)
         setShowReportSubmenu(false)
      }
   }, [openSubmenu])
   const handleChangeSubmenu = (index) => {
      dispatch(setOpenSubmenu({
         openSubmenu: index
      }))
   }

    return (
      <div>
      <button data-drawer-target="sidebar-multi-level-sidebar" data-drawer-toggle="sidebar-multi-level-sidebar" aria-controls="sidebar-multi-level-sidebar" type="button" className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
         <span className="sr-only">Open sidebar</span>
         <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd" fill-rule="evenodd" d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
         </svg>
      </button>
      <div id="sidebar-multi-level-sidebar" className="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full sm:translate-x-0" aria-label="Sidebar"             style={{backgroundImage: 'linear-gradient(#FD853A, #FB6514)'}}
      >
         <div 
            className="h-4/5 px-3 pt-4 overflow-y-auto"
         >
         <img className="mb-10 mt-5" src={logoSidebar}></img>
         <ul className="space-y-2 font-medium">
            <li>
               <a href="/dashboard" className={`${isLocationMatch && props.indexActive == '0' ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                  <DashboardSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '0' ? '#FB6514' : ''}`} />
                  <span className={`${isLocationMatch && props.indexActive == '0' ? 'text-white' : ''} ms-3 text-sidebar-item`}>Dashboard</span>
               </a>
            </li>
            <li>
               <button onClick={() => setShowEInvoiceSubmenu(!showEInvoiceSubmenu)} type="button" className={`cursor-pointer ${isLocationMatch && props.indexActive == '1' && !props.childIndexActive ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group w-full text-base transition duration-75 group`}>
                  <EInvoiceSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '1' && !props.childIndexActive ? '#FB6514' : ''}`} />
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-sidebar-item">e-Inovice</span>
                  <DropdownSidebarIcon />
               </button>
               {
                  showEInvoiceSubmenu && (
                     <ul id="einvoice-dropdown" className="py-2 space-y-2">
                     <li>
                        <a onClick={() => handleChangeSubmenu('1')} href="/e-invoice" className={`${isLocationMatch && props.indexActive == '1' && props.childIndexActive == '0' ? 'chosen-sidebar' : ''} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                           <CircleSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '1' && props.childIndexActive == '0' ? '#FB6514' : ''}`} />
                           <span className={`${isLocationMatch && props.indexActive == '1' && props.childIndexActive == '0' ? '' : 'submenu-text'} ms-3 text-sidebar-item`}>e-Invoice Listing</span>
                        </a>
                     </li>
                     <li>
                        <a onClick={() => handleChangeSubmenu('1')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                           <CircleSidebarIcon />
                           <span className="ms-3 text-sidebar-item submenu-text">Issue Manual e-Invoice</span>
                        </a>
                     </li>
                     <li>
                        <a onClick={() => handleChangeSubmenu('1')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                           <CircleSidebarIcon />
                           <span className="ms-3 text-sidebar-item submenu-text">Issue Self-billed Manual e-Invoice</span>
                        </a>
                     </li>
                  </ul>
                  )
               }
            </li>
            <li>
               <button onClick={() => setShowBatchUploadSubmenu(!showBatchUploadSubmenu)} type="button" className={` ${isLocationMatch && props.indexActive == '6' ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group w-full text-base transition duration-75 group`}>
                  <BatchUploadSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '6' ? '#FB6514' : ''}`} />
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-sidebar-item">Batch Upload</span>
                  <DropdownSidebarIcon />
               </button>
               {
                  showBatchUploadSubmenu && (
                     <ul id="batch-upload-dropdown" className="py-2 space-y-2">
                        <li>
                           <a onClick={() => handleChangeSubmenu('6')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">Batch Upload History</span>
                           </a>
                        </li>
                        <li>
                           <a onClick={() => handleChangeSubmenu('6')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">Manual e-Invoice</span>
                           </a>
                        </li>
                        <li>
                           <a onClick={() => handleChangeSubmenu('6')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">Manual Consolidated e-Invoice</span>
                           </a>
                        </li>
                        <li>
                           <a onClick={() => handleChangeSubmenu('6')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">Self-billed Manual e-Invoice</span>
                           </a>
                        </li>
                     </ul>
                  )
               }

            </li>
            <li>
               <button onClick={() => setShowReportSubmenu(!showReportSubmenu)} type="button" className={` ${isLocationMatch && props.indexActive == '2' ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group w-full text-base transition duration-75 group`}>
                  <ReportSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '2' ? '#FB6514' : ''}`} />
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-sidebar-item">Reports</span>
                  <DropdownSidebarIcon />
               </button>
               {
                  showReportSubmenu && (
                     <ul id="report-dropdown" className="py-2 space-y-2">
                        <li>
                           <a onClick={() => handleChangeSubmenu('2')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">GL Reconcilliation</span>
                           </a>
                        </li>
                        <li>
                           <a onClick={() => handleChangeSubmenu('2')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">Transaction Details</span>
                           </a>
                        </li>
                     </ul>
                  )
               }
            </li>
         </ul>
         <ul className="space-y-2 font-medium border-t border-gray-200 dark:border-gray-700 separator mb-auto">
            <li>
               <a href="#" className={`${isLocationMatch && props.indexActive == '3' ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                  <InboxSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '3' ? '#FB6514' : ''}`} />
                  <span className="flex-1 ms-3 whitespace-nowrap text-sidebar-item">Inbox</span>
                  <span className="inline-flex items-center justify-center w-3 h-3 p-3 ms-3 text-sm font-medium notification-count">4</span>
               </a>
            </li>
            <li>
               <button onClick={() => setShowManagementSubmenu(!showManagementSubmenu)} type="button" className={` ${isLocationMatch && props.indexActive == '4' && !props.childIndexActive ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group w-full text-base transition duration-75 group`}>
                  <ManagementSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '4' && !props.childIndexActive ? '#FB6514' : ''}`} />
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-sidebar-item">Management</span>
                  <DropdownSidebarIcon />
               </button>
               {
                  showManagementSubmenu && (
                     <ul id="report-dropdown" className="py-2 space-y-2">
                        <li>
                           <a onClick={() => handleChangeSubmenu('4')} href="/management-user" className={`${isLocationMatch && props.indexActive == '4' && props.childIndexActive == '0' ? 'chosen-sidebar' : ''} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '4' && props.childIndexActive == '0' ? '#FB6514' : ''}`} />
                              <span className={`${isLocationMatch && props.indexActive == '4' && props.childIndexActive == '0' ? '' : 'submenu-text'} ms-3 text-sidebar-item`}>User</span>
                           </a>
                        </li>
                        <li>
                           <a onClick={() => handleChangeSubmenu('4')} href="#" className={`flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                              <CircleSidebarIcon />
                              <span className="ms-3 text-sidebar-item submenu-text">Role</span>
                           </a>
                        </li>
                     </ul>
                  )
               }
            </li>
            <li>
               <a href="#" className={`${isLocationMatch && props.indexActive == '5' ? 'chosen-sidebar' : 'inactive-sidebar'} flex items-center p-2 text-orange-900 rounded-lg dark:text-white hover:bg-orange-100 dark:hover:bg-orange-700 group`}>
                  <LogSidebarIcon filledColor={`${isLocationMatch && props.indexActive == '5' ? '#FB6514' : ''}`} />
                  <span className="flex-1 ms-3 text-left rtl:text-right whitespace-nowrap text-sidebar-item">Log Managements</span>
                  <DropdownSidebarIcon />
               </a>
            </li>
         </ul>
         <div className="fixed bottom-10 justify-center content-center ">
            <div className="flex justify-center content-center flex-row h-17 bottom-container-sidebar">
               <span className="mt-3 submenu-text bottom-initial-container-sidebar">B</span>
               <div className="flex flex-col submenu-text mt-3 ml-2">
                  <span className="name-text-sidebar ">Bella Isabella</span>
                  <span className="title-text-sidebar">AFX-A • Manager</span>
               </div>
               <img className="mt-4 mb-4 ml-5" src={tripleDotSidebar} width="24px" height="24px"/>
            </div>
            <img src={logoBottomSidebar} width="156px" height="16px" className=" ml-8 bottom-1"></img>
         </div>
         </div>
      </div>
   </div>
   );
}

export default Sidebar;