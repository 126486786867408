import React, { useState, useEffect } from "react";

import { DropdownSidebarIcon } from '../../../assets/images'
import Sidebar from "../../../components/Sidebar/Sidebar.component";
import MainContainer from "../../../components/MainContainer/MainContainer.component";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb.component";
import PieChart from "../../../components/PieChart/PieChart.component"
import SearchBar from "./SearchBar";
import LegendItem from "../../../components/LegendItem";
import { chunk } from "../../../utils/arrayUtils";
import './styles.css'
import TableEInvoice from "./TableEInvoice";
import { useAppDispatch } from "../../../redux/store";
import { setOpenSubmenu } from "../../../redux/menuReducer";

const EInvoiceListing = (props) => {
    const [showMenuNavigation, setShowMenuNavigation] = useState(false)
    const dispatch = useAppDispatch()
    const breadCrumbData = [
        {
           name: 'Home',
           path: '/dashboard',
           isLastIndex: false
        },
        {
           name: 'e-Invoice',
           path: '/e-invoice',
           isLastIndex: true
        }
    ]
    const pieDataStatus = [
        { name: 'Validated', value: 300000 },
        { name: 'Reject', value: 190000 },
        { name: 'Invalid', value: 51000 },
    ]
    const pieDataType = [
        { name: 'Invoice', value: 300000 },
        { name: 'Credit Note', value: 190000 },
        { name: 'Debit Note', value: 51000 },
        { name: 'Refund Note', value: 300000 },
        { name: 'Self-billed Invoice', value: 190000 },
        { name: 'Self-billed Credit Note', value: 51000 },
        { name: 'Self-billed Debit Note', value: 300000 },
        { name: 'Self-billed Refund Note', value: 190000 }
    ]
    const STATUS_COLORS = ['#27AE60', '#F2994A', '#F04438']
    const TYPE_COLORS = [['#1DC6A7', '#2F80ED', '#BC5FDD', '#F42F6A'], ['#F2994A', '#4A7F8A', '#F2C94C', '#FF96F5']]

    useEffect(() => {
        dispatch(setOpenSubmenu({
            openSubmenu: '1'
        }))
    }, [])

    return (
        <>         
            <Sidebar
                currentLocation="/e-invoice"
                indexActive="1"
                childIndexActive="0"
            />
            <div className="main-content-container mr-5">
                <div className="flex flex-row justify-between">
                    <div>
                        <Breadcrumb data={breadCrumbData}></Breadcrumb>
                        <h1 className="main-title">e-Invoice</h1>
                    </div>
                    <div className="py-5">
                        <button type="button" onClick={() => setShowMenuNavigation(!showMenuNavigation)} className={`flex items-center p-2 text-white group w-full text-base transition duration-75 group einvoice-menu-button`} aria-controls="einvoice-menu-dropdown" data-collapse-toggle="einvoice-menu-dropdown" aria-expanded="false">
                            <span className="flex-1 ms-3 text-left rtl:text-right einvoice-menu-text">Issue e-Inovice</span>
                            <DropdownSidebarIcon />
                        </button>
                        <div id="einvoice-menu-dropdown" className={`absolute z-10 ${!showMenuNavigation && 'hidden'} bg-white divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700`}>
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="einvoice-menu-dropdown">
                                <li>
                                    <a href="#" onClick={() => setShowMenuNavigation(!showMenuNavigation)} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white einvoice-submenu-text">Manual e-Invoice</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => setShowMenuNavigation(!showMenuNavigation)} className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white einvoice-submenu-text">Self-Billed Manual e-Invoice</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <SearchBar />
                <div className="flex flex-row w-full content-between justify-between" style={{marginTop: '30px'}}>
                    <MainContainer>
                        <div className="flex flex-row justify-between pt-3 einvoice-title-graph-container">
                            <div className="flex flex-row">
                                <h1 className="einvoice-title-graph-text">e-Invoice Status</h1>
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-full">
                            <PieChart data={pieDataStatus} colors={STATUS_COLORS} />
                            <div className="flex flex-col mx-10 mt-10 w-2/5">
                                {
                                    pieDataStatus.map((data, index) => (
                                        <LegendItem legendText={data.name} count={data.value} bgColor={STATUS_COLORS[index]}/>
                                    ))
                                }

                            </div>
                        </div>
                    </MainContainer>
                    <MainContainer containerStyle={{
                        width: '45vw'
                    }}>
                        <div className="flex flex-row justify-between pt-3 einvoice-title-graph-container">
                            <div className="flex flex-row">
                                <h1 className="einvoice-title-graph-text">Validated by Type</h1>
                            </div>
                        </div>
                        <div className="flex flex-row w-full h-full">
                            <PieChart data={pieDataType} colors={[...TYPE_COLORS[0], ...TYPE_COLORS[1]]} />
                            <div className="flex flex-row mx-1 mt-2 w-full">
                                {
                                    chunk(pieDataType, 4).map((data, firstIndex) => (
                                        <div className="flex flex-col ml-2">
                                            {
                                                data.map((submap, secondIndex) => (
                                                    <LegendItem legendText={submap.name} count={submap.value} bgColor={TYPE_COLORS[firstIndex][secondIndex]}/>
                                                ))
                                            }
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </MainContainer>
                </div>
                <MainContainer containerStyle={{
                        width: '100%',
                        marginTop: '10px',
                        height: '100%',
                        marginBottom: '20px',
                        padding: '10px'
                    }}>
                        <TableEInvoice />
                </MainContainer>
            </div>
        </>
    )
}

export default EInvoiceListing;