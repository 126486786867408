import React from "react";
import { useNavigate } from 'react-router-dom'
import { useMsal } from '@azure/msal-react'

import { authenticatedUser } from "../../redux/authenticationReducer";
import { useAppDispatch } from "../../redux/store";
import { backgroundLogin, logoLogin, microsoftLogoLogin } from "../../assets/images"
import './styles.css'

const Login = () => {
    const {instance} = useMsal()
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const handleClick = () => {
        instance
            .loginPopup()
            .then((response) => {
                dispatch(authenticatedUser({
                    isAuthenticated: true,
                    token: response.accessToken,
                    refreshToken: null,
                    userPermissions: [],
                    userAccessManagement: []
                }))
                setTimeout(() => {
                    navigate('/dashboard', { replace: true, state: {
                        token: response
                    } })
                }, 3000)

            })
            .catch(error => {
                navigate('/unauthorized')
            })
    }

    return (
        <div
            className="outer-container"
            style={{
                background: `url(${backgroundLogin})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat:' no-repeat'
            }}
        >
            <div className="inner-container">
                <div className="row-container" >
                    <div className="first-row"
                        onClick={handleClick}
                    >
                        <img 
                            width={'245px'}
                            height={'93px'}
                            src={logoLogin} />
                        <h1 className="welcome-text">Welcome back!</h1>
                        <p className="sign-in-text">Sign in with your Microsoft account to access our web portal effortlessly.</p>
                        <div className="btn-signin" >
                            <img 
                                src={microsoftLogoLogin}
                                width={'24px'}
                                height={'24px'}
                                className="btn-logo"/>
                            <h1 className="btn-text">
                                Sign In
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login