import React from "react";
import { useNavigate } from "react-router-dom";

import FailedLogin from "../../components/Login/FailedLogin.component";

const AccessDenied = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/dashboard', { replace: true })
    }
    
    return (<FailedLogin handleClick={handleClick} isDenied />)
}

export default AccessDenied;