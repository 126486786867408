import React from "react";

const EInvoiceDoubleArrowRightIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : 'white'
    
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" className="w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M10.7316 8.35356L11.0852 8.00001L10.7316 7.64645L7.87916 4.79402C7.81193 4.72679 7.81193 4.61765 7.87916 4.55042C7.94639 4.48319 8.05553 4.48319 8.12276 4.55042L11.4509 7.87854L11.8044 7.52499L11.4509 7.87854C11.5183 7.94601 11.5177 8.0548 11.4513 8.12103L11.4509 8.12147L8.12276 11.4496C8.05553 11.5168 7.94639 11.5168 7.87916 11.4496C7.81193 11.3823 7.81193 11.2732 7.87916 11.206L10.7316 8.35356ZM7.44004 7.6676L7.44005 7.66761C7.5226 7.75462 7.52231 7.90536 7.44026 7.99174L7.44004 7.99197L4.79802 10.7766C4.76061 10.8159 4.71821 10.8305 4.68001 10.8305C4.64181 10.8305 4.59941 10.8159 4.562 10.7766C4.47982 10.69 4.47982 10.5391 4.562 10.4526L4.5622 10.4524L6.72409 8.17394L7.05064 7.82978L6.72409 7.48563L4.5622 5.20716L4.5622 5.20715C4.47964 5.12015 4.47994 4.9694 4.56199 4.88302C4.5994 4.84365 4.6418 4.82904 4.68001 4.82904C4.71819 4.82904 4.76056 4.84363 4.79795 4.88294C4.79797 4.88297 4.798 4.883 4.79802 4.88302L7.44004 7.6676Z" fill="white" stroke={filledColor}/>
        </svg>
    )
}

export default EInvoiceDoubleArrowRightIcon;