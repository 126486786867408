import React from "react";

const InboxSidebarIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : 'white'
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M15.135 12.3599C14.2549 13.0932 13.1456 13.4948 12 13.4948C10.8544 13.4948 9.74511 13.0932 8.865 12.3599L0.75 5.50488V18.4424L5.4675 13.7249C5.61098 13.602 5.79553 13.5378 5.98429 13.5451C6.17305 13.5524 6.3521 13.6306 6.48567 13.7642C6.61924 13.8978 6.69749 14.0768 6.70478 14.2656C6.71207 14.4543 6.64787 14.6389 6.525 14.7824L1.8075 19.4999H22.1925L17.475 14.7824C17.3521 14.6389 17.2879 14.4543 17.2952 14.2656C17.3025 14.0768 17.3808 13.8978 17.5143 13.7642C17.6479 13.6306 17.827 13.5524 18.0157 13.5451C18.2045 13.5378 18.389 13.602 18.5325 13.7249L23.25 18.4424V5.50488L15.135 12.3599Z" fill={filledColor}/>
            <path d="M14.175 11.25L22.125 4.5H1.875L9.825 11.25C10.4428 11.7429 11.2097 12.0113 12 12.0113C12.7903 12.0113 13.5572 11.7429 14.175 11.25Z" fill={filledColor}/>
        </svg>
    )
}

export default InboxSidebarIcon;