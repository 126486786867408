const objectStyle = {
    color: 'white',
    borderRadius: '14px',
    width: '110px',
    height: '27px',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight:'500',
    lineHeight: '20px'
}

const renderTextColor = (eInvoiceType) => {
    if (eInvoiceType == 'Invoice') {
        return {
            color: '#1DC6A7'
        }
    }
    if (eInvoiceType == 'Credit Note') {
        return {
            color: '#2F80ED'
        }
    }
    if (eInvoiceType == 'Debit Note') {
        return {
            color: '#BC5FDD'
        }
    }
    if (eInvoiceType == 'Refund Note') {
        return {
            color: '#F04438'
        }
    }
    if (eInvoiceType == 'Self-billed Invoice') {
        return {
            color: '#F2994A'
        }
    }
    if (eInvoiceType == 'Self-billed Credit Note') {
        return {
            color: '#4A7F8A'
        }
    }
    if (eInvoiceType == 'Self-billed Debit Note') {
        return {
            color: '#F2C94C'
        }
    }
    if (eInvoiceType == 'Self-billed Refund Note') {
        return {
            color: '#FF96F5'
        }
    }
}

const renderTextBackgroundInvoiceStatus = (invoiceStatus) => {
    if (invoiceStatus == 'Submitted') {
        return {
            ...objectStyle,
            backgroundColor: '#2F80ED'
        }
    }
    if (invoiceStatus == 'Draft') {
        return {
            ...objectStyle,
            backgroundColor: '#98A2B3'
        }
    }
    if (invoiceStatus == 'Failed') {
        return {
            ...objectStyle,
            backgroundColor: '#F04438'
        }
    }
    if (invoiceStatus == 'Invalid') {
        return {
            ...objectStyle,
            backgroundColor: '#9C2A10'
        }
    }
    if (invoiceStatus == 'Rejected') {
        return {
            ...objectStyle,
            backgroundColor: '#F2994A'
        }
    }
    if (invoiceStatus == 'Valid') {
        return {
            ...objectStyle,
            backgroundColor: '#27AE60'
        }
    }
    if (invoiceStatus == 'Cancelled') {
        return {
            ...objectStyle,
            backgroundColor: '#4A7F8A'
        }
    }
}

const renderTextBackgroundApprovalStatus = (approvalStatus) => {
    if (approvalStatus == 'Rejected') {
        return {
            ...objectStyle,
            backgroundColor: '#F04438'
        }
    }
    if (approvalStatus == 'Pending') {
        return {
            ...objectStyle,
            backgroundColor: '#F2994A'
        }
    }
    if (approvalStatus == 'Approved') {
        return {
            ...objectStyle,
            backgroundColor: '#27AE60'
        }
    }
}

export {
    renderTextColor,
    renderTextBackgroundInvoiceStatus,
    renderTextBackgroundApprovalStatus
}