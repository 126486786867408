import React from "react"

import CustomSelect from "../CustomSelect"
import { EInvoiceArrowLeftIcon, EInvoiceArrowRightIcon, EInvoiceDoubleArrowLeftIcon, EInvoiceDoubleArrowRightIcon } from "../../assets/images"

export const CustomPagination = props => {
  const optionsData = [
    { label: "5", value: 5 },
    { label: "10", value: 10 },
    { label: "15", value: 15 }
  ]
  const onButtonClick = newPage => {
    if (newPage === props.totalPage || newPage < 0) return
    props.onPageChange({
      "page-size": props.value["page-size"],
      "current-page": newPage
    })
  }
  const onChangeSelect = event => {
    props.onPageChange({ "page-size": event.value, "current-page": 0 })
  }
  return (
    <>
      <div className="flex justify-end items-center gap-4 px-5 py-3">
        <p className="font-lato font-[400] text-[14px] text-[#667085]">
          Items per page
        </p>
        <CustomSelect
          options={optionsData}
          multiple={false}
          menuPosition="top"
          onChange={e => onChangeSelect(e)}
          defaultValue={{ label: "5", value: 5 }}
        />
        <p className="block font-lato text-base antialiased font-normal leading-relaxed text-gray-700">
          {" "}
          <strong className="text-[#667085] font-lato">
            {props.value["current-page"] + 1}
          </strong>
          <strong className="text-[#667085] font-lato">
            {" "} - {" "}{props.totalData < (props.value["current-page"] + 1)*10 ? props.totalData :(props.value["current-page"] + 1)*10 }
          </strong>
          {"  "}
          of
          {"  "}
          <strong className="text-[#667085] font-lato">
            {props.totalData}
          </strong>
        </p>
        <button
            style={{
                borderColor: '#D0D5DD'
            }}
          className={`flex content-center justify-center items-center h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg border border-gray-900 text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          type="button"
          onClick={() => onButtonClick(props.value["current-page"] - 1)}
        >
            <EInvoiceDoubleArrowLeftIcon />
        </button>
        <button
         style={{
            borderColor: '#D0D5DD'
         }}
          className={`flex flex-row justify-center items-center h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg border border-gray-900 text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          type="button"
          onClick={() => onButtonClick(props.value["current-page"] - 1)}
        >
            <EInvoiceArrowLeftIcon />
        </button>
        <button
          className={`flex justify-center items-center h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg border border-gray-900 text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          type="button"
          onClick={() => onButtonClick(props.value["current-page"] + 1)}
        >
            <EInvoiceArrowRightIcon filledColor="#667085" />
        </button>
        <button
          className={`flex justify-center items-center h-8 max-h-[32px] w-8 max-w-[32px] select-none rounded-lg border border-gray-900 text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:opacity-75 focus:ring focus:ring-gray-300 active:opacity-[0.85] disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none`}
          type="button"
          onClick={() => onButtonClick(props.value["current-page"] + 1)}
        >
            <EInvoiceDoubleArrowRightIcon filledColor="#667085" />
        </button>
      </div>
    </>
  )
}

export default CustomPagination
