import React from "react";

const EInvoiceArrowLeftIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : '#D0D5DD'
    
    return (
        <svg width="16px" height="16px" viewBox="0 0 16 16" className="mt-2 ml-3 w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none">
            <path d="M5 1L1 5L5 9" stroke={filledColor} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

export default EInvoiceArrowLeftIcon;