import React from "react";

import './styles.css'

const TextView = (props) => {
    return (
        <h1 className={`text-view-text ${props.classStyle}`} style={props.textStyles}>{props.text}</h1>
    )
}

export default TextView