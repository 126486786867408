import React from "react";

import Sidebar from "../../components/Sidebar/Sidebar.component";
import SearchBar from "./SearchBar";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb.component";
import { PlusManagementIcon } from "../../assets/images";
import MainContainer from "../../components/MainContainer/MainContainer.component";
import Table from "./Table";

const ManagementUser = () => {
    const breadCrumbData = [
        {
           name: 'Home',
           path: '/dashboard',
           isLastIndex: false
        },
        {
           name: 'Management - User',
           path: '/management-user',
           isLastIndex: true
        }
    ]
    return (
        <>
            <Sidebar
                currentLocation="/management-user"
                indexActive="4"
                childIndexActive="0"
            />
            <div className="main-content-container mr-5">
                <div className="flex flex-row justify-between">
                    <div>
                        <Breadcrumb data={breadCrumbData}></Breadcrumb>
                        <h1 className="main-title">User</h1>
                    </div>
                    <div className="py-5">
                        <button type="button" className={`flex items-center p-2 text-white group w-full text-base transition duration-75 group einvoice-menu-button`}>
                            <PlusManagementIcon className="mt-2 ml-5 mr-5" />
                            <span className="flex-1 text-left rtl:text-right einvoice-menu-text"> Add User</span>
                        </button>
                    </div>
                </div>
                <SearchBar />
                <MainContainer containerStyle={{
                        width: '100%',
                        marginTop: '30px',
                        height: '100%',
                        marginBottom: '20px',
                        padding: '10px'
                    }}>
                        <Table />
                </MainContainer>
            </div>
        </>
    )
}

export default ManagementUser