import React from "react";

const EInvoiceFilterIcon = (props) => {
    const filledColor = props.filledColor ? props.filledColor : '#EC4A0A'
    
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" className="w-5 h-5 text-orange-500 transition duration-75 dark:text-orange-400 group-hover:text-orange-900 dark:group-hover:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <path d="M8.27162 13.125C8.11224 13.125 7.97865 13.071 7.87084 12.9632C7.76304 12.8553 7.70914 12.7217 7.70914 12.5622C7.70914 12.4028 7.76304 12.2692 7.87084 12.1615C7.97865 12.0538 8.11224 12 8.27162 12H9.72113C9.8805 12 10.0141 12.0539 10.1219 12.1618C10.2297 12.2696 10.2836 12.4033 10.2836 12.5627C10.2836 12.7222 10.2297 12.8557 10.1219 12.9634C10.0141 13.0711 9.8805 13.125 9.72113 13.125H8.27162ZM5.36537 9.56246C5.20601 9.56246 5.07242 9.50854 4.96461 9.40069C4.85679 9.29283 4.80289 9.15918 4.80289 8.99974C4.80289 8.84029 4.85679 8.70672 4.96461 8.59903C5.07242 8.49134 5.20601 8.4375 5.36537 8.4375H12.6274C12.7867 8.4375 12.9203 8.49143 13.0282 8.59928C13.136 8.70714 13.1899 8.84079 13.1899 9.00023C13.1899 9.15968 13.136 9.29324 13.0282 9.40093C12.9203 9.50862 12.7867 9.56246 12.6274 9.56246H5.36537ZM3.1875 5.99998C3.02813 5.99998 2.89453 5.94605 2.78672 5.83819C2.67891 5.73034 2.625 5.59669 2.625 5.43724C2.625 5.2778 2.67891 5.14424 2.78672 5.03655C2.89453 4.92885 3.02813 4.875 3.1875 4.875H14.8125C14.9718 4.875 15.1054 4.92893 15.2132 5.03679C15.3211 5.14466 15.375 5.27831 15.375 5.43774C15.375 5.59719 15.3211 5.73076 15.2132 5.83845C15.1054 5.94614 14.9718 5.99998 14.8125 5.99998H3.1875Z" fill={filledColor}/>
        </svg>
    )
}

export default EInvoiceFilterIcon;