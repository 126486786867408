import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { UrlString } from '@azure/msal-common';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const isAuthPopup = () => {
  const hash = window.location.hash;
  return UrlString.hashContainsKnownProperties(hash);
}

// check if we are in the auth popup
// if so, render a simple message
// otherwise, render the app
if (isAuthPopup()) {
  root.render(<div>
    <h1>Authenticating...</h1>
  </div>);
} else {
  root.render(<App />);
}

// root.render(
//   // <React.StrictMode>
//     // <BrowserRouter>
//       <App />
//     // </BrowserRouter>
//   // </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
