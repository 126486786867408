import React from 'react'
import {
    ResponsiveContainer,
    XAxis,
    YAxis,
    Legend,
    Bar,
    Rectangle,
    BarChart,
    Tooltip
} from 'recharts'

import './chart-styles.css'

const Chart = (props) => {
    return (
        <ResponsiveContainer width="100%" height="100%" className="box-container">
            <BarChart
                data={props.data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <text x="150px" y="18" fill="black" textAnchor="middle" dominantBaseline="central">
                    <tspan className='title-graph'>Overall Invoice</tspan>
                </text>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend layout='horizontal' verticalAlign='top' align='right'/>
                <Bar
                    name="Failed"
                    dataKey="failed"
                    fill="#F44336"
                    activeBar={<Rectangle fill="#dc2626" stroke="white" />}
                />
                <Bar
                    name="Completed"
                    dataKey="completed"
                    fill="#27AE60"
                    activeBar={<Rectangle fill="#16a34a" stroke="white" />}
                />
                <Bar
                    name="Rejected"
                    dataKey="reject"
                    fill="#F2994A"
                    activeBar={<Rectangle fill="#F2994A" stroke="white" />}
                />
            </BarChart>
        </ResponsiveContainer>
    )   
}

export default Chart
