import React from "react";

import './noaccess-item-styles.css'

const NoAccessItem = (props) => {
    const { imageUrl, imageWidth, imageHeight, detailText, styleContainer } = props
    return (
        <>
            <div className="noaccess-container flex flex-col items-center justify-center" style={styleContainer}>
                <img src={imageUrl} width={imageWidth} height={imageHeight}></img>
                <h1 className="noaccess-detail-text mt-2">{detailText}</h1>
            </div>
        </>
    )
}

export default NoAccessItem