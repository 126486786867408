import React from "react"
import Select, { components } from "react-select"

const CustomSelect = ({
  iconSrc,
  options,
  multiple,
  menuPosition,
  id,
  disabled,
  name,
  defaultValue,
  value,
  onChange,
  onBlur,
  customBorderClass
}) => {
  const customStyles = {
    control: provided => ({
      ...provided
    }),
    menuPortal: provided => ({ ...provided, zIndex: 9999 }),
    menu: provided => ({ ...provided, zIndex: 9999 }),
    indicatorSeparator: prevStyle => ({
      ...prevStyle,
      display: "none"
    })
  }

  const Control = ({ children, ...props }) => (
    <components.Control {...props} className={customBorderClass}>
      {iconSrc ? (
        <img src={iconSrc} className="w-[16px] h-[16px] ml-2" />
      ) : null}{" "}
      {children}
    </components.Control>
  )
  const handleOnChange = e => {
    // const value = e.target.value
    if (onChange) onChange(e)
  }
  return (
    <Select
      options={options}
      components={{ Control }}
      defaultValue={defaultValue}
      styles={customStyles}
      isMulti={multiple}
      menuPlacement={menuPosition}
      menuPortalTarget={document.body}
      menuPosition={"fixed"}
      isDisabled={disabled}
      name={name}
      id={id}
      data-testid={id}
      onChange={handleOnChange}
      value={value}
      onBlur={onBlur}
    />
  )
}

export default CustomSelect
